// 领航者
<template>
  <div class="partyCulture-pilot">
    <Info @setData="setData" :isData="isData" class="pilot-info back-border" />
    <InfoImg v-if="isData.length" :isData="isData" @setData="setData" :gutter="20" field="logo" />
  </div>
</template>

<script>
import Info from '@/components/Info'
import InfoImg from '@/components/InfoImg'
export default {
  components: {
    Info,
    InfoImg
  },
  data () {
    return {
      isData: []
    }
  },
  methods: {
    setData (data) {
      this.isData = data
    }
  },
};
</script>

<style scoped lang="less">
/deep/ .pilot-info {
  padding: 38px 53px 48px;
  margin-bottom: 32px;
  p {
    line-height: 25px;
  }
}
/deep/ .infoImg-item {
  margin-bottom: 22px;
}
</style>
